export const pageview = url => {
    if (window.gtag != undefined){
       window.gtag('config', 'G-.......', {
        page_path: url
      })   
    }
  
    }
    export const event = ({ action, category, label, value }) => {
      window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value
      })
    }