import { useContext, useEffect } from "react";
import { Context } from "../../../context";
import { useRouter } from "next/router";
import Image from "next/image";
import Link from "next/link";
import { AiOutlineLogout } from "react-icons/ai";
import { FiChevronDown } from "react-icons/fi";

import { MdOutlineHouse, MdOutlineDashboard , MdOutlineMarkChatUnread} from "react-icons/md";
import { useUserProfile } from "../../../hooks/auth";
import styles from "./dashboardProfile.module.scss";

// menus
// const userMenus = [
//     { name: "Dashboard", icon: <MdOutlineHouse />, link: "/dashboard" },
// //   { name: "Projects", icon: <MdOutlineHouse />, link: "/user/projects" },
//   { name: "Logout", icon: <AiOutlineLogout />, link: "/logout" },
// ];

export default function DashboardProfile() {
  const router = useRouter();
  const { data: user, isLoading, error } = useUserProfile();

  useEffect(() => {
    if (!user) {
      router.push("/auth");
    }
  }, [user, router]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.userProfile}>
        <div className={styles.userImg}>
          <Image
            src={
              user && user.profilePicture ? user.profilePicture : "/avatar.jpg"
            }
            alt="Profile"
            width={45}
            height={45}
          />
        </div>
        <h2 className={styles.userName}>
          {user && user.name ? user.name : "Your full name"}
        </h2>
        <span className={styles.icon}>
          <FiChevronDown />
        </span>
      </div>
      <div className={styles.userMenu}>
        <ul className={styles.menuLists}>
          <li>
            <Link href={"/credits"}>
              <span className={styles.uMIcon}>
                <MdOutlineDashboard size={16} />
              </span>
              <span className={styles.uName}>Credits</span>
            </Link>
          </li>
          <li>
            <Link href={"/user/projects"}>
              <span className={styles.uMIcon}>
                <MdOutlineHouse size={18} />
              </span>
              <span className={styles.uName}>Projects</span>
            </Link>
          </li>
          <li>
            <Link href={"/user/chats"}>
              <span className={styles.uMIcon}>
                <MdOutlineMarkChatUnread size={14} />
              </span>
              <span className={styles.uName}>Messages</span>
            </Link>
          </li>
          <li>
            <Link href={"/logout"}>
              <span className={styles.uMIcon}>
                <AiOutlineLogout size={16} />
              </span>
              <span className={styles.uName}>Logout</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
