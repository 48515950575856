import { useRouter } from "next/router";
import styles from "./button.module.scss";

export default function Button({
  type,
  variant,
  size = "md",
  gradient,
  btnHelper = "",
  btnHelperClass = "",
  icon,
  iconPlacement,
  link = "",
  onClick,
  children,
  loading,
  id,
  disabled,
}) {
  const router = useRouter();

  const handleRouteChange = (link) => {
    router.push(link);
  };

  let buttonSizeClass;
  switch (size) {
    case "huge":
      buttonSizeClass = styles.btnSizeHuge;
      break;
    case "lg":
      buttonSizeClass = styles.btnSizeLg;
      break;
    case "md":
      buttonSizeClass = styles.btnSizeMd;
      break;
    case "sm":
      buttonSizeClass = styles.btnSizeSm;
      break;
    case "xs":
      buttonSizeClass = styles.btnSizeXs;
      break;
    default:
      buttonSizeClass = styles.btnSizeMd;
      break;
  }

  let buttonVariantClass;
  if (variant) {
    buttonVariantClass = styles[variant];
  }

  let buttonGradientClass;
  if (gradient) {
    buttonGradientClass = styles.btnGradient;
  }

  return (
    <div
      className={`${styles.btnHelper}${btnHelperClass && " " + btnHelperClass}${
        btnHelper && " " + styles[btnHelper]
      }`}
    >
      <button
        className={`${styles.btn} ${buttonSizeClass} ${buttonVariantClass} ${
          gradient === true ? buttonGradientClass : ""
        } ${loading === true ? styles.loading : ""} `}
        type={type || "button"}
        id={id} disabled={disabled}
        onClick={link ? ()=>handleRouteChange(link) : onClick}
      >
        {icon && iconPlacement !== "right" && (
          <span className={styles.icon}>{icon}</span>
        )}
        {children}
        {iconPlacement === "right" && (
          <span className={styles.icon}>{icon}</span>
        )}
      </button>
    </div>
  );
}
