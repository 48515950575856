import { createContext, useEffect } from "react";
import axios from "axios";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const Context = createContext();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 0,
      staleTime: 10 * 1000, // Adjust stale time as needed
    },
  },
});

const Provider = ({ children }) => {
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const res = error.response;
        if (res.status === 401 && res.config && !res.config.__isRetryRequest) {
          try {
            console.log("Logged out");            
            await axios.get("/api/logout");
            return Promise.reject(error);
          } catch (err) {
            console.error("AXIOS INTERCEPTORS ERR", err);
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  useEffect(() => {
    const getCsrfToken = async () => {
      const { data } = await axios.get("/api/csrf-token");
      axios.defaults.headers["X-CSRF-Token"] = data.csrfToken;
    };
    getCsrfToken();
  }, []);

  return <Context.Provider value={{}}>{children}</Context.Provider>;
};

const QueryProvider = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <Provider>{children}</Provider>
  </QueryClientProvider>
);

export { Context, QueryProvider as Provider };
