import { Provider } from '../../../context';
import { HeaderProvider } from '../../../context/HeaderContext';
import { WindowProvider } from '../../../context/WindowContext';
import { ChatProvider } from '../../../context/ChatContext'
import { useRouter } from 'next/router';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import styles from './layout.module.scss';

export default function Layout({ children }) {
    const router = useRouter()
    return (
      <WindowProvider>
        <HeaderProvider>
          <ChatProvider>
            <Provider>
              <Header />
              <main className={styles.main}>{children}</main>
              {router.pathname.includes("chat") ? (
                <></>
              ) : (
                //dont show footer on any chat page
                <Footer />
              )}
            </Provider>
          </ChatProvider>
        </HeaderProvider>
      </WindowProvider>
    );
}

