import Image from "next/image"
import styles from "./LayoutFillImg.module.scss"
export default function LayoutFillImg({src, alt, overlay,  lazyLoad=true, quality=20}) {
  return (
    <div style={{ cursor: "pointer" }} className={styles.container}>
      <Image
        src={src}
        alt={alt}
        layout="fill"
        objectPosition="center"
      
        loading={lazyLoad === true ? "lazy" : "eager"}
        quality={quality}

      />
      {overlay && <div className={styles.overlay}></div>}
    </div>
  );
}
