import { HeaderProvider } from './HeaderContext';
import { WindowProvider } from './WindowContext';
import { Provider } from '.';

export default function Providers({ children }) {
    return (
        <Provider>
            <WindowProvider>
                <HeaderProvider>{children}</HeaderProvider>
            </WindowProvider>
        </Provider>
    );
}
