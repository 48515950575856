import Image from "next/image";
import Link from "next/link";
import logo from "../../../public/logo.png";
import Container from "../container/Container";
import Socials from "../socials/Socials";
import Text from "../text/Text";
import styles from "./footer.module.scss";

export default function Footer() {
  const date = new Date();

  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.footerBottom}>
          <span className={styles.copyText}>
            &copy;{date.getFullYear()} Copyright <span>Empire</span>. All rights
            reserved -
            <Text className={styles.licensetext}>License Number: 1047945</Text>
          </span>

          <div>
            <Link href="/">
              <span className={styles.footLink}>Cookies Settings</span>
            </Link>
          </div>
        </div>
      </Container>
    </footer>
  );
}
