import { createContext, useContext, useEffect, useState } from "react";
import { useUserProfile } from "@/hooks/auth";

const ChatContext = createContext(null);

export function ChatProvider({ children }) {
  const [selectedChat, setSelectedChat] = useState();
  const [messages, setMessages] = useState([]); //enables us to search using dom filter
  const [chatUser, setChatUser] = useState();
  const [notifications, setNotifications] = useState([]);
  const [totalUnreadMessages, setTotalUnreadMessages] = useState(0);
  const [chats, setChats] = useState([]);
  const [fetchAgain, setFetchAgain] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(null);

  console.log("totalUnreadMessages", totalUnreadMessages);

 const { data: user } = useUserProfile();
  useEffect(() => {
    if (user) {
      setChatUser(user);
    }
  }, [user]);

  return (
    <ChatContext.Provider
      value={{
        selectedChat,
        setSelectedChat,    
        selectedChannel,
        setSelectedChannel,
        messages,
        setMessages,
        chatUser,
        setChatUser,
        notifications,
        setNotifications,
        totalUnreadMessages,
        setTotalUnreadMessages,
        chats,
        setChats,
        fetchAgain,
        setFetchAgain,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}

export function UseChatContext() {
  const chatContext = useContext(ChatContext);
  return chatContext;
}
