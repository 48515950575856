import Image from "next/image";
import Link from "next/link";
import { useCallback, useEffect, useRef, useState, useContext } from "react";
import { BiEnvelope,  } from "react-icons/bi";
import { FiSearch, FaSettings } from "react-icons/fi";
import { FaRegBell } from "react-icons/fa6";
import adjustIcon from "@/assets/icons/adjust-setting.svg";
import notification from "@/assets/icons/notification.svg";
import { UseHeaderContext } from "@/context/HeaderContext";
import { UseWindowContext } from "@/context/WindowContext";
import { UseChatContext } from "@/context/ChatContext";
import logo from "@/public/outdoorbuilderslogo-removebg-preview.png";
import DashboardProfile from "@/components/Misc/dashboardProfile/DashboardProfile";
import { BiMenuAltRight, BiUserPlus } from "react-icons/bi";
import { MdOutlineClose } from "react-icons/md";
import { AiOutlineLogout } from "react-icons/ai";
import { FiChevronDown, FiUser } from "react-icons/fi";
import { Context } from "../../../context";
import { useUserProfile } from "@/hooks/auth";

import { MdOutlineDashboard, MdOutlineMarkChatUnread } from "react-icons/md";
import styles from "./chatHeader.module.scss";

// menus
const userMenus = [
  { name: "Dashboard", icon: <MdOutlineDashboard />, link: "/user" },
  { name: "Profile", icon: <FiUser /> },
  { name: "Chat", icon: <MdOutlineMarkChatUnread />, link: "/user/chat" },
  { name: "Logout", icon: <AiOutlineLogout />, link: "/logout" },
];

export default function ChatHeader() {
  const [search, setSearch] = useState("");
  const { setChatHeaderHeight } = UseHeaderContext();
  const { windowWidth } = UseWindowContext();
  const overlayRef = useRef(null);
  const menusWrapperRef = useRef(null);
  const headerRef = useRef(null);
  const menuTopRef = useRef(null);

  // const {
  //   state: { user },
  // } = useContext(Context);
  const { data: user, isLoading: userLoading } = useUserProfile();
  const { notifications, messages, setMessages, totalUnreadMessages } = UseChatContext();

  // condition
  const windowCondition = windowWidth < 992;

  // const getHeaderHeight = useCallback(() => {
  //   setChatHeaderHeight(headerRef.current.getBoundingClientRect().height);
  // }, [setChatHeaderHeight]);

  // // set header height
  // useEffect(() => {
  //   getHeaderHeight();
  //   window.addEventListener("resize", getHeaderHeight);
  //   return () => window.removeEventListener("resize", getHeaderHeight);
  // }, [getHeaderHeight]);

  const handleSearch = (e) => {
    e.preventDefault();
    //filter messages
    const filteredMessages =
      messages &&
      messages.filter((message) =>
        message.content.toLowerCase().includes(search)
      );
    setMessages(filteredMessages);
  };

  // on menu click
  const menuToggler = (type, overType) => {
    const overlay = overlayRef.current;
    const menu = menusWrapperRef.current;

    if (type === "OPEN") {
      if (overType !== "noOverlay") {
        overlay.style.opacity = 1;
        overlay.style.pointerEvents = "all";
      }
      menu.style.transform = `translateX(0)`;
    } else {
      menu.style.transform = `translateX(100%)`;
      overlay.style.opacity = 0;
      overlay.style.pointerEvents = "none";
    }
  };
  return (
    <header ref={headerRef} className={styles.header}>
      <div className={styles.logoWrapper}>
        <div className={styles.logo}>
          <Link href="/" aria-label="Logo">
            <Image src={logo} alt="Logo" width={130} />
          </Link>
        </div>
      </div>
      <div className={styles.pagSear}>
        {/* <div className={styles.channelPagination}>
                    <span className={styles.channelIcon}>
                        <BiHash />
                    </span>
                    <ul className={styles.paginations}>
                        <li>
                            <Link href="/chat">Bio study</Link>
                            <span className={styles.divider}>/</span>
                        </li>
                        <li>Study question</li>
                    </ul>
                </div> */}
        <form className={styles.search} onSubmit={handleSearch}>
          <button type="submit" className={styles.submit}>
            <FiSearch />
          </button>
          <input
            className={styles.input}
            type="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search here..."
            required
          />
          <div className={styles.adjust}>
            <Image src={adjustIcon} alt="Adjust" />
          </div>
        </form>
      </div>
      <div className={styles.profileWrapper}>
      <div className={styles.notify}>
        <button type="button" className={`${styles.notifyIcon}`}>
          <BiEnvelope size={18} />

          <div className={`${styles.activity} ${styles.activityCount}`}>
            <span>{totalUnreadMessages}</span>
          </div>
        </button>
        <button type="button" className={`${styles.notifyIcon}`}>
          <FaRegBell size={18} />
          {notifications && (
            <div className={`${styles.activity} ${styles.activityCount}`}>
              <span>{notifications.length}</span>
            </div>
          )}
        </button>
      </div>
      <div className={styles.profileInfo}>
        <div className={styles.nav}>
          {windowCondition && (
            <button
              type="button"
              className={styles.menuBar}
              onClick={() => menuToggler("OPEN")}
            >
              <BiMenuAltRight />
            </button>
          )}
          <div
            className={styles.overlay}
            ref={overlayRef}
            onClick={menuToggler}
          />
          <div className={styles.menusWrapper} ref={menusWrapperRef}>
            {windowCondition && (
              <>
                <div className={styles.menuTopBar} ref={menuTopRef}>
                  <div className={styles.logo}>
                    <Link href="/">
                      <Image src={logo} alt="Outdoor Builders Logo" />
                    </Link>
                  </div>
                  <button className={styles.menuClose} onClick={menuToggler}>
                    <MdOutlineClose />
                  </button>
                </div>
                <div className={styles.userMenu}>
                  <ul className={styles.menuLists}>
                    {userMenus.map(({ name, icon, link }, i) => {
                      return (
                        <li key={i}>
                          <Link
                            href={
                              name == "Profile"
                                ? `/user/${user?.username}`
                                : link
                            }
                          >
                            <span className={styles.uMIcon}>{icon}</span>
                            <span className={styles.uName}>{name}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
        {windowWidth > 992 && <DashboardProfile variant="style-2" />}
      </div>
      </div>
    </header>
  );
}
